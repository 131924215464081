import React from "react";
import { Box } from "@mui/material";
import banner_couple from "../assets/banner_couple.png";

function HomepageCard() {
  return (
      <Box sx={{ height: "100vh", overflow: "hidden" }}>
        <Box className="banner-section">
          <img src={banner_couple} alt="GeeksforGeeks Logo" loading="lazy" />
        </Box>
      </Box>
  );
}

export default HomepageCard;
