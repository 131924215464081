import { Box, FormControl, Grid } from "@mui/material";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetMethod } from "../api/fetch";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import { useUserContext } from "../context/user-context";

function YourPreferencePage() {
  const navigate = useNavigate();
  const { setUserData, userData } = useUserContext();

  const [fetchSexOrientation, setFetchSexOrientation] = useState([]);
  const [fetchSexualInterest, setFetchSexualInterest] = useState([]);
  const [fetchRelationshipStatus, setFetchRelationshipStatus] = useState([]);

  useEffect(() => {
    GetMethod("getConstants").then((res: any) => {
      setFetchSexOrientation(res?.data?.data?.SEXUAL_ORIENTATION || []);
      setFetchSexualInterest(res?.data?.data?.INTRESTED_IN_SEEING || []);
      setFetchRelationshipStatus(res?.data?.data?.RELATIONSHIP_STATUS || []);
    });
  }, []);

  console.log("userData", userData)

  const handlePreference = (values: {
    sexOrientation: any;
    sexualInterest: any;
    relationshipStatus: any;
  }) => {
    setUserData((prevData) => ({
      ...prevData,
      sex_orientation: values.sexOrientation,
      sexual_interest: values.sexualInterest,
      relationship_status: values.relationshipStatus,
    }));

    navigate("/study-signup");
  };

  const initialValues = {
    sexOrientation: "",
    sexualInterest: "",
    relationshipStatus: "",
  };

  const validationSchema = Yup.object().shape({
    sexOrientation: Yup.string().required(
      "Please select your sexual orientation"
    ),
    sexualInterest: Yup.string().required("Please select your sexual interest"),
    relationshipStatus: Yup.string().required(
      "Please select your relationship status"
    ),
  });

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={6} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>
          <Box sx={{ mt: { xs: 3, md: 5 } }} className="common_card_container">
            <h2 className={styles.phone_texthead}>Your Preferences...</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handlePreference}
            >
              {() => (
                <Form>
                  <FormControl sx={{ width: "100%" }} className="input-box">
                    <Field
                      as="select"
                      name="sexOrientation"
                      className="custom-select-dropdown"
                    >
                      <option value="" disabled>
                        Select Sexual Orientation
                      </option>
                      {fetchSexOrientation.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Field>
                    <p
                      style={{ color: "red", fontSize: "14px" }}
                      className="m-2"
                    >
                      <ErrorMessage name="sexOrientation" />
                    </p>

                    <Field
                      as="select"
                      name="sexualInterest"
                      className="custom-select-dropdown"
                    >
                      <option value="" disabled>
                        Select Sexual Interest
                      </option>
                      {fetchSexualInterest.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Field>
                    <p
                      style={{ color: "red", fontSize: "14px" }}
                      className="m-2"
                    >
                      <ErrorMessage name="sexualInterest" />
                    </p>

                    <Field
                      as="select"
                      name="relationshipStatus"
                      className="custom-select-dropdown"
                    >
                      <option value="" disabled>
                        Select Relationship Status
                      </option>
                      {fetchRelationshipStatus.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Field>
                    <p
                      style={{ color: "red", fontSize: "14px" }}
                      className="m-2"
                    >
                      <ErrorMessage name="relationshipStatus" />
                    </p>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <button className="next_button" type="submit">
                          NEXT
                        </button>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="deny_button"
                          onClick={() => navigate(-1)}
                        >
                          BACK
                        </Box>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default YourPreferencePage;
