import React from "react";
import {  Box,Container } from "@mui/material";
import Box_one from "../assets/box_one.jpg";
import box_two from "../assets/box_two.jpg";
import HomeSlider from "./HomeSlider";
import  Grid  from "@mui/material/Grid2";

function HomeScreen() {
  return (
    <Box>
      <HomeSlider/>
      <Container maxWidth="xl">



      <Grid className="main_box" container rowSpacing={{ xs: 2, sm: 2, md: 0 }} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid className="main_img_box"   size={{ xs: 12, md: 6 }}>
        <img src={Box_one} alt="box one" className="box_img"/>
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
          <p>
          Build a dating app geared toward one specific niche or lifestyle, so users feel more confident that they can find their partner on your app. You'll attract users who are specifically interested in what you're offering, people who know exactly what they want in a partner and believe they can find them on your app.
          For some people, it's really important to connect with someone who shares their interests and lifestyle preferences. But a lot of the widely used apps and dating sites like OkCupid, Match.com, and Bumble don't cater to a specific niche, making it harder for people to make a meaningful connection with someone who wants the same things they do.
          </p>
        </Grid>
      </Grid>

      <Grid className="main_box mid_box" container  rowSpacing={{ xs: 2, sm: 2, md: 0 }} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          <p>
          Most apps require their users to come up with these talking points themselves, which can be intimidating. Many users don't know what to say or what stuff to include, and most apps don't give users any indication of how long their answers should be or what they should say. Without any prompts or indicators of things to say, it can be harder for people to show their true personalities through their bios and icebreakers, making it difficult for users to find meaningful matches.

One way you can implement AI is by generating responses that people can add to their user profile and use in conversations. Once users sign up, send them a quick questionnaire about their personality and things they like. Ask questions like, "What do your friends say is their favorite thing about you?"
          </p>
        </Grid>
        <Grid className="main_img_box"  size={{ xs: 12, md: 6 }}>
        <img src={box_two} alt="box two" className="box_img"/>
        </Grid>
      </Grid>
      <Grid className="main_box bottom_txt_box" container>
        <Grid size={{ xs: 12, md: 12, lg: 12 }}>
          <h2 className="bottom_heading"> WHAT WE DO? </h2>
          <p>
          Online dating can be overwhelming, stressful, and challenging for some people. Ease their nerves by adding entertaining games to your dating app. You can even make a game out of the entire experience.

While people wait for messages from potential matches, it can be fun to give them something else to do rather than just stare at their screens. Whether it's playing against other users in a game of chess or checkers or just giving them something to pass the time, like word searches or tic-tac-toe, your app will keep people coming back to it over and over again.

          </p>
          <p>
           
One way to make a game out of the entire experience and encourage repeat use of your app is to offer rewards, badges, or points when users complete certain tasks. These could include things like completing daily matches, sending first messages, or following through on meeting in person after chatting online.
          </p>
        </Grid>
      </Grid>

      </Container>

    </Box>
  );
}

export default HomeScreen;
