import React from "react";
import { Grid, Box, FormControl, Select, MenuItem } from "@mui/material";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import arrow_icon from "../assets/arrow_icon.svg";

function AboutUs() {
  return (
    <>
    
      {/* <Box className="custom-margin" sx={{ display: "flex", justifyContent: "center"}}>
        <LogoWhiteDude />
      </Box> */}
      <Box className="custom-margin" >
        <Box className="main_top_heading">
          <span className="arrow_icon">
            <img src={arrow_icon} alt="arrow" />
          </span>
          <span className="main_sec_text">About Us</span>
        </Box>
        <Box className="main_sec_content">
          <p>
          Give your users the option to securely send gifts to their matches, such as a delivery meal, flowers, candy, or a coffee gift card.

One thing missing from current online dating apps on the market is the opportunity to "woo" your matches. When you date in real life, you can show up for a date with candy or flowers, but when you start dating through an app, it can feel less like a true human connection. While your users get to know each other, you can offer them the option to send their matches a gift to help them build genuine connections with each other.
</p>
          <p>
         
If you add this option to your app, make it secure so the person sending the gift can't see the recipient's address or other personal information. Users will have to approve this for security purposes. During signup, you can populate a form that asks, "Would you like the option to send and receive a small gift from your matches? If so, enter your mailing and email address." Ensure users that their matches won't be able to see this information.
</p>
          <p>
         
You can also try to set up a partnership with a meal delivery service like DoorDash or Grubhub to make it easier for users to send a delivery meal to their match. Or partner with a flower delivery company, so users can send their matches flowers. </p>
          <p>
          Create an app that matches users based on their astrological sign, Enneagram, Myers-Briggs assessment, and other personality indicators that are important to them. There are a lot of people who use these indicators—to some degree—when they assess how compatible they are with someone.</p>
        </Box>
        
      </Box>
    </>
  );
}

export default AboutUs;
