import { Box } from "@mui/material";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import { PostMethod } from "../api/fetch";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { getCountryCode } from "../utils/helper";
import toast from "react-hot-toast";

function ContinuePhonePage() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validatePhoneNumber = (number: string) => {
    const { withoutCountryCode } = getCountryCode(number);
    const isValid = withoutCountryCode && withoutCountryCode.trim().length > 8;
    if (!isValid) {
      setErrorMessage("Please enter a valid phone number.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handletoContinue = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      return; // Prevent submission if invalid
    }

    const payload = {
      mobile: phoneNumber,
      countryCode: getCountryCode(phoneNumber).withoutCountryCode,
    };

      const response: any = await PostMethod("loginWithMobile", payload)
        .then((res) => res)
        .catch((err) => {
          toast.error(err?.response?.data?.message)
        });

      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "token",
          JSON.stringify(response?.data?.data?.token)
        );
        toast.success("OTP sent successfully!");
        navigate("/verification-number"); // Uncomment to navigate after successful response
      }

  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
          <LogoWhiteDude />
          <Box className="common_card_container" sx={{ mt: { xs: 3, md: 5 } }}>
            <h2 className={`${styles.phone_texthead} ${styles.main_text}`}>
              Continue with Mobile Number
            </h2>

            <Box className="continuePhone" sx={{ mt: { xs: 1, md: 2 } }}>
              <h3 className={styles.phoneNumber}>Phone Number</h3>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}{" "}
              {/* Error message display */}
              <Box className="next_button" onClick={handletoContinue}>
                NEXT
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContinuePhonePage;
