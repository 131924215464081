import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface UserContextType {
    userData: Record<string, any>; // Replace with more specific type if needed
    setUserData: React.Dispatch<React.SetStateAction<Record<string, any>>>; // Replace with more specific type if needed
}

// Create a default context value
const defaultUserContextValue: UserContextType = {
    userData: {},
    setUserData: () => {}, // Placeholder function
};

// Create the UserContext with the default value
const UserContext = createContext<UserContextType>(defaultUserContextValue);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [userData, setUserData] = useState<Record<string, any>>({});

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUserContext = () => useContext(UserContext);
