import LogoWhiteDude from "../utils/LogoWhiteDude";
import google_play from "../assets/google_play.png";
import play_store from "../assets/play_store.png";
import { Link } from "react-router-dom";
// import { Grid } from "@mui/system";
import Grid from "@mui/material/Grid2";
import Stack from '@mui/material/Stack';

const Footer = () => {
  return (
    <Grid className="footer " container spacing={{ xs: 1, sm: 1, md: 2, lg:2 }}>
      <Grid className="footer_box" size={{ xs: 12, sm:6, md: 6, lg: 3, xl:4 }}  sx={{ justifyContent: { xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' } }} >
        <LogoWhiteDude />
      </Grid>
      <Grid className="footer_box" size={{ xs: 12, sm:6, md: 6, lg: 3 , xl:4 }}>
        
      <Stack
          direction={{ xs: 'row', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 3 }}   sx={{
            justifyContent: { xs: 'center', sm: 'center', md: 'end' },
          }}
        >

          <Link to="gogle.com.in">
            {" "}
            <img src={google_play} alt="box one" className="footer_img" />{" "}
          </Link>

          <Link to="gogle.com.in">
            {" "}
            <img src={play_store} alt="box one" className="footer_img" />{" "}
          </Link>

        </Stack>
      </Grid>
      <Grid className="footer_box" size={{ xs: 12, sm:12, md: 12, lg: 6, xl:5  }}>
        <Stack  direction={{ xs: 'row', sm: 'row' }} className="footer-ul " sx={{
            justifyContent: { xs: 'center', sm: 'center', md: 'center', lg: 'end' },
          }}>
          <li className="footer_link">
            <Link to="/about-us">About Us</Link>
          </li>
          <li className="footer_link">
            <Link to="/term-of-services">Terms of Service</Link>
          </li>
          <li className="footer_link">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li className="footer_link">
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
