import { Box, FormControl, Select, MenuItem } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import userLogo1 from "../assets/image1.png";
import userLogo2 from "../assets/image2.png";
import upload_icon from "../assets/upload_icon.png";
import register_icon from "../assets/tick_icon.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent } from "react";
import { PostMethod } from "../api/fetch";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";

interface ImageObject {
  file: File;
  dataURL: string;
}

function UploadImagePic() {
  const navigate = useNavigate();

  const [imageSelected, setImage] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [OtpError, setOtpError] = useState("");

  const [imageData, setImageData] = useState<ImageObject[]>([]);

  const ProfileImage = [
    { id: 0, name: "PP_1", image: userLogo1 },
    { id: 1, name: "PP_2", image: userLogo2 },
  ];

  const handleImage = (e: any) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
  };

  const fullname: any = localStorage.getItem("fullName");
  const sexOrientation: any = localStorage.getItem("sexOrientation");
  const gender: any = localStorage.getItem("gender");
  const DOB: any = localStorage.getItem("DOB");
  // const state: any = localStorage.getItem("state");
  const relationshipStatus: any = localStorage.getItem("relationshipStatus");
  const sexualInterest: any = localStorage.getItem("sexualInterest");
  // const instituteStudy: any = localStorage.getItem("instituteStudy");
  const email: any = localStorage.getItem("email");
  const preference: any = localStorage.getItem("preference");
  // const city: any = localStorage.getItem("city");
  const Hobby: any = localStorage.getItem("hobby");
  const lookingFOR: any = localStorage.getItem("lookingFOR");
  const longitude: any = localStorage.getItem("longitude");
  const latitude: any = localStorage.getItem("latitude");
  const state_id: any = localStorage.getItem("stateID");
  const city_id: any = localStorage.getItem("cityID");
  // const ArrayImages: any[] = ["imagetwo.jpg"];

  const handleAPIUserInfo = () => {
    setOtpError("");
    const formData = new FormData();

    formData.append("email", email);
    formData.append("full_name", fullname);
    formData.append("dob", DOB);
    formData.append("gender", gender);
    formData.append("sexual_orientation", sexOrientation);
    formData.append("intrested_in", sexualInterest);
    formData.append("relationship_status", relationshipStatus);
    formData.append("looking_for", lookingFOR);
    formData.append("state_id", state_id);
    formData.append("city_id", city_id);
    formData.append("distance", preference);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("hobby", Hobby);
    imageData.forEach((imageObj, index) => {
      formData.append("images", imageObj.file);
    });

    PostMethod("user/saveAdditionalInfo", formData)
      .then((res: any) => {
        console.log(res);
        setOpen(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setOtpError("Please Slect Atleast 1 Image");
        }
        if (err.response.status === 400) {
          setOtpError("OTP is not allowed to be Empty");
        }
      });
  };

  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files) as File[];

      const imagePromises = selectedImages.map((image) => {
        return new Promise<ImageObject>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve({
              file: image,
              dataURL: event.target?.result as string,
            });
          };
          reader.onerror = (error) => {
            reject(error);
          };
          // Explicitly cast 'image' to Blob
          reader.readAsDataURL(image as Blob);
        });
      });

      Promise.all(imagePromises)
        .then((imageDataArray) => {
          setImageData([...imageData, ...imageDataArray]);
          console.log("Uploaded images:", imageDataArray);
        })
        .catch((error) => {
          console.error("Error uploading images: ", error);
        });
    }
  };

  const handleSubmit = () => {
    // Here, you can handle the submission of imageData (array of objects) as needed
    console.log("Submitting:", imageData);
    // You might send this imageData array to a server, perform further processing, etc.
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        {/* <Box className="container_section upload_section"> */}
        <Box
  className={`container_section  ${imageData.length > 4 ? "upload_section" : ""}`}
  sx={{ mt: { xs: 3, md: 4 } }}
>

        <Box className="login-section">
        <LogoWhiteDude />
        </Box>

        <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 4 }}}>
          
            <h2 className={styles.phone_texthead}>Upload Image</h2>

            <Box sx={{ display: "block", flexWrap: "wrap", width:"100%" }}>
              <div className="image-upload" style={{textAlign:'center', marginBlock:"15px"}}>
                <label htmlFor="file-input">
                  {/* <img
                    src={upload_icon}
                    alt="upload"
                    style={{
                      margin: "10px",
                      height: "160px",
                      cursor: "pointer", 
                     
                    }}
                  /> */}
                  <span>+</span>
                </label>

                <input
                  id="file-input"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                />
              </div>
            </Box>
            <div className="uploaded-img ">
              {imageData.map((imageObj, index) => (
                <img
                  key={index}
                  src={imageObj.dataURL}
                  alt={`preview-${index}`}
                  style={{
                    maxWidth: "100%",
                    height:"100%",
                    maxHeight: "110px",
                    minHeight: "110px",
                    objectFit: "cover",

                 
                  }}
                />
              ))}
            </div>
            {OtpError ? (
  <p style={{ color: 'red', fontSize: '14px', margin: '10px', fontWeight: 500 }}>
    {OtpError}
  </p>
) : null}
            <Grid container spacing={2} sx={{ width:"100%", marginBottom:'10px' }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="next_button"
               
                  onClick={handleAPIUserInfo}
                  // onClick={handleSubmit}
                  // onClick={handleClickOpen}
                >
                  SUBMIT
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="deny_button"
                  sx={{  width: "100%" }}
                  onClick={() => navigate(-1)}
                >
                  BACK
                </Box>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              maxWidth={maxWidth}
              fullWidth={fullWidth}
              onClose={handleClose}
            >
              <Box sx={{ display: "flex", justifyContent: "center", m: 6 }}>
                <img src={register_icon} alt="successfully done" />
              </Box>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box className="success_text">
                  You have Registered Successfully Enjoy!
                </Box>

                <Box
                  className="next_button"
                  sx={{ width: "60%" }}
                  onClick={() => navigate("/Homepage")}
                  //   onClick={handlePreference}
                >
                  CONTINUE
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UploadImagePic;
