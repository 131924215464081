import {
  Box,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";

function ContactUs() {
  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [DOB, setDOB] = useState("");

  const handleChange = (event: any) => {
    setGender(event.target.value);
  };

  const handleUserDetails = () => {
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("email", email);

    navigate("/your-preference");
  };
  return (
    <Grid container>
       <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
        <Box className="login-section">
        <LogoWhiteDude />
        </Box>

        <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            <h2 className={styles.phone_texthead}>Contact Us</h2>

            <OutlinedInput
              placeholder="Enter Your Email Address"
              sx={{
                width: "100%",
                fontFamily: '"Josefin Sans", sans-serif', 
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #cacaca",
                    }
                },
                mt: 2,
            }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <OutlinedInput
              placeholder="Message/ Comment"
              sx={{
                width: "100%",
                fontFamily: '"Josefin Sans", sans-serif', 
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #cacaca",
                    }
                },
                mt: 2,
            }}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <Grid container spacing={2} sx={{ width: "100%" }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="next_button"
                  // onClick={() => navigate("/study-signup")}
                  //   onClick={handlePreference}
                >
                  NEXT
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box className="deny_button" sx={{ width: "100%" }}>
                  BACK
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
