import { AsYouType } from 'libphonenumber-js';

export function getCountryCode(input: string) {
    // Set default country code to US if no real country code is specified
    const defaultCountryCode = input.charAt(0) !== '+' ? 'US' : undefined; // Use undefined instead of null
    let formatted = new AsYouType(defaultCountryCode).input(input);
    let countryCode: any = '';
    let withoutCountryCode = formatted;

    if (defaultCountryCode === 'US') {
        countryCode = '+1';
        formatted = '+1 ' + formatted;
    } else {
        const parts = formatted.split(' ');
        countryCode = parts.length > 1 ? parts.shift() : '';
        withoutCountryCode = parts.join(' ');
    }

    return {
        formatted,
        withoutCountryCode,
        countryCode,
    };
}
