import { Box } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";

function LogoWhiteDude() {
  return (
    <Box className="login-section"      >
      <img src={white_dude_banner} alt="white Logo" loading="lazy"/>
    </Box>
  );
}

export default LogoWhiteDude;