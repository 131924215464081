import { Box, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import { Label } from "@mui/icons-material";

function WhatIntoIt() {
  const navigate = useNavigate();

  const [age, setAge] = useState("");

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  const handleWhatIntoIt = (values:any) => {
    // localStorage.setItem("hobby", values.yourHobby);
    navigate("/upload-image");
  };

  // -------- Login form Initial Values -------
  const initialValues = {
    yourHobby: "",
  };

  const loginvalidation = Yup.object().shape({
    yourHobby: Yup.string().required("Your State Field is required"),
  });

  return (
    <Grid container>
     <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
        <Box className="login-section">
        <LogoWhiteDude />
        </Box>

        <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            <h2 style={{marginBottom:"0px"}} className={styles.phone_texthead}>What are you Into?</h2>

          

            <FormControl sx={{ m: 2, width: "100%" }}>
              <Formik
                initialValues={initialValues}
                // validationSchema={loginvalidation}
                onSubmit={handleWhatIntoIt}
              >
                <Form>
                 <h4 style={{ marginBlock: "0px", color: "#616161" , fontWeight:"normal", textAlign:"center" }}>
              Select your hobbies
            </h4>
            {/* <InputLabel id="">Select your hobbies</InputLabel> */}

                  <Field
                    as="select"
                    className="custom-select-dropdown"
                    style={{ marginTop: "5px", }}
                    id="yourHobby"
                    name="yourHobby"
                  
                  >
                    <option value="" disabled>
                      {" "}
                      Select Preference{" "}
                    </option>
                    <option value="Football">Football</option>
                    <option value="Cricket">Cricket</option>
                    <option value="BasketBall">BasketBall</option>
                    <option value="Other">Other</option>
                  </Field>
                  <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                    <ErrorMessage name="yourHobby" />
                  </p>

                  {/* <Select
                sx={{ m: 1 }}
                value={age}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  You’re Looking For..
                </MenuItem>
                <MenuItem value={"Football"}>Football</MenuItem>
                <MenuItem value={"Cricket"}>Cricket</MenuItem>
                <MenuItem value={"BasketBall"}>BasketBall</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select> */}

                  <Grid container spacing={2}>
                    <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                      <button
                        className="next_button"
                        type="submit"
                        // onClick={() => navigate("/upload-image")}
                        // onClick={handleWhatIntoIt}
                      >
                        NEXT
                      </button>
                    </Grid>
                    <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                      <button
                        className="deny_button"
                        style={{ width: "100%" }}
                        onClick={() => navigate(-1)}
                      >
                        BACK
                      </button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WhatIntoIt;
