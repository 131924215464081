import {  Box, Typography } from "@mui/material";
import  Grid  from "@mui/material/Grid2";
import fb_icon from "../assets/fbIcon.svg";
import instagram_icon from "../assets/instagram_icon.svg";
import twitter_icon from "../assets/twitterIcon.svg";
import phone_icon from "../assets/phoneIcon.svg";
import HomepageCard from "../components/HomepageCard";
import { useNavigate } from "react-router-dom";
import LogoWhiteDude from "../utils/LogoWhiteDude";

function Login() {

  const navigate = useNavigate();
  return (
    <Grid container className="login-container">
      <Grid size={{ xs: 12, sm: 6, }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6, }}  className="login-form">
        <Box className="container_section_login">
          <LogoWhiteDude />
          <Box className="social-media-container" sx={{ mt: { xs: 3, md: 5 }}}>
            <Box className="socialmedia-box">
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs:2, sm:2, md:2}} >
                  <img src={fb_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs:10, sm:10, md:10}}>
                  <h5 className="signup-page-typo"> CONTINUE WITH FACEBOOK</h5>
                </Grid>
              </Grid>
            </Box>
            <Box className="socialmedia-box">
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2, }}>
                  <img src={instagram_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid  size={{ xs:10, sm:10, md:10}}>
                  <h5 className="signup-page-typo">CONTINUE WITH INSTAGRAM</h5>
                </Grid>
              </Grid>
            </Box>
            <Box className="socialmedia-box">
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size= {{ xs:2, sm:2, md:2}}>
                  <img src={twitter_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size= {{ xs:10, sm:10, md:10}}>
                  <h5 className="signup-page-typo">CONTINUE WITH TWITTER</h5>
                </Grid>
              </Grid>
            </Box>
            <Box className="socialmedia-box" onClick={() => navigate("/continue-with-number")}>
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size= {{ xs:2, sm:2, md:2}}>
                  <img src={phone_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs:10, sm:10, md:10}}>
                  <Grid size={{ xs:10, sm:10, md:10}}>
                    <h5 className="signup-page-typo">CONTINUE WITH PHONE</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
