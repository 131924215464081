import { Box, FormControl, Select, MenuItem } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import smile from "../assets/smile.png";
import like from "../assets/like.png";
import inlove from "../assets/inlove.png";
import heart from "../assets/heart.png";
import happy from "../assets/happy.png";
import confuse from "../assets/confuse.png";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";

function LookingForPage() {
  const navigate = useNavigate();

  const handleNextButton = (event: any) => {
    navigate("/what-intoIt")
  };

  const handleLookingFor = (item: any) => {
    console.log(item.looking);
    localStorage.setItem("LookingFOR",item.looking);
  }

  const LookingFor = [
    { id:1, name: "CP_1",image: like, looking:'Long Term Partner' },
    { id:2, name: "CP_2",image: smile, looking:'Long  Term but, Short Term Ok'},
    { id:3, name: "CP_3",image: heart, looking:'New Friends'},
    { id:4, name: "CP_4",image: happy, looking:'Short Term Fun'},
    { id:5, name: "CP_5",image: confuse, looking:'Still Figuring it Out'},
    { id:6, name: "CP_6",image: inlove, looking:'Short Term but, Long Term Ok' }
  ]

  return (
    <Grid container>
    <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
      <HomepageCard />
    </Grid>
    <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
      <Box className="container_section lookingforpage">
      <Box className="login-section">
        <LogoWhiteDude />
        </Box>
       
        <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            <h2 className={styles.phone_texthead}>You’re Looking For..</h2>

            <Grid className="main_box" container spacing={0}>
              {LookingFor.map((item:any,index:any) => {
                return(
                  <Grid className="icon_box" size={{ xs: 4, sm: 6, md: 6}} 
                    onClick={(event) => handleLookingFor(item)}>
                    <img src={item.image} alt="box one" className="icon_img" style={{cursor:'pointer'}} />
                    <p>{item.looking}</p>
                  </Grid>
                )
              })}
              {/* <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={like} alt="box one" className="icon_img"/>
              <p> Long Term <br/>Partner</p>
              </Grid>
              <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={smile} alt="box one" className="icon_img"/>
              <p> Long  Term but,<br/> Short Term Ok </p>
              </Grid>
              <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={heart} alt="box one" className="icon_img"/>
              <p> New <br/> Friends </p>
              </Grid>
              <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={happy} alt="box one" className="icon_img"/>
              <p> Short Term <br/> Fun</p>
              </Grid>
              <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={confuse} alt="box one" className="icon_img"/>
              <p> Still Figuring <br/> it Out </p>
              </Grid>
              <Grid className="icon_box" item xs={4} md={4} lg={4}>
              <img src={inlove} alt="box one" className="icon_img"/>
              <p> Short  Term but, <br/>Long Term Ok </p>
              </Grid> */}
            </Grid>
            {/* <FormControl sx={{ m: 2, width: "100%" }}>
              <Select
                sx={{ m: 1 }}
                value={lookingForCheck}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  You’re Looking For..
                </MenuItem>
                <MenuItem value={"Long Term Partner"}>Long Term Partner </MenuItem>
                <MenuItem value={"Long Term but, Short Term Ok"}>Long Term but, Short Term Ok</MenuItem>
                <MenuItem value={"New Friends"}>New Friends</MenuItem>
                <MenuItem value={"Short Term Fun"}>Short Term Fun</MenuItem>
                <MenuItem value={"Still Figuring it Out"}>Still Figuring it Out</MenuItem>
                <MenuItem value={"Short Term but, Long Term Ok"}>Short Term but, Long Term Ok </MenuItem>
              </Select>
            </FormControl> */}

            <Box className="next_button looking_for_btn" sx={{ mb: 1 }}
            onClick={handleNextButton}
            //  onClick={() => navigate("/what-intoIt")} 
             >
              NEXT
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LookingForPage;
