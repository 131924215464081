import axios from "axios";
import { DEV_HOSTNAME_URL } from "../constants";

export const PostMethod = (url: any, data: any) => {
  const TOKEN: any = localStorage?.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .post(`${DEV_HOSTNAME_URL}/api/${url}`, data, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          token: JSON.parse(TOKEN),
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const GetMethod = (url: any) => {
  const TOKEN: any = localStorage?.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get(`${DEV_HOSTNAME_URL}/api/${url}`, {
        headers: {
          "Content-type": "application/json",
          token: JSON.parse(TOKEN),
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
