import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Login from "./pages/Login";
import ContinuePhonePage from "./pages/ContinuePhonePage";
import VerifyOtpNo from "./pages/VerifyOtpNo";
import UserBasicDetails from "./pages/UserBasicDetails";
import YourPreferencePage from "./pages/YourPreferencePage";
import StudySignup from "./pages/StudySignup";
import LocationGPS from "./pages/LocationGPS";
import LocationPreference from "./pages/DistancePreference";
import DistanceSlider from "./pages/DistanceSlider";
import LookingForPage from "./pages/LookingForPage";
import WhatIntoIt from "./pages/WhatIntoIt";
import UploadImagePic from "./pages/UploadImagePic";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./otherPages/AboutUs";
import HomeScreen from "./pages/HomeScreen";
import TermsOfServices from "./otherPages/TermsOfServices";
import PrivacyPolicy from "./otherPages/PrivacyPolicy";
import Layout from "./components/Layout";
import ErrorPage from "./pages/ErrorPage"; // Add an error page component
import { UserProvider } from "./context/user-context";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <HomeScreen />,
        },
        {
          path: "/continue-with-number/",
          element: <ContinuePhonePage />,
        },
        {
          path: "/verification-number/",
          element: <VerifyOtpNo />,
        },
        {
          path: "/basic-details/",
          element: <UserBasicDetails />,
        },
        {
          path: "/your-preference/",
          element: <YourPreferencePage />,
        },
        {
          path: "/study-signup/",
          element: <StudySignup />,
        },
        {
          path: "/location-gps/",
          element: <LocationGPS />,
        },
        {
          path: "/distance-preference/",
          element: <DistanceSlider />,
        },
        {
          path: "/your-location/",
          element: <LocationPreference />,
        },
        {
          path: "/looking-for/",
          element: <LookingForPage />,
        },
        {
          path: "/what-intoIt/",
          element: <WhatIntoIt />,
        },
        {
          path: "/upload-image/",
          element: <UploadImagePic />,
        },
        {
          path: "/contact-us/",
          element: <ContactUs />,
        },
        {
          path: "/about-us/",
          element: <AboutUs />,
        },
        {
          path: "/term-of-services/",
          element: <TermsOfServices />,
        },
        {
          path: "/privacy-policy/",
          element: <PrivacyPolicy />,
        },
        {
          path: "/register/",
          element: <Login />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;