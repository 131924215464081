import {  Box, OutlinedInput } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";

function StudySignup() {
  const navigate = useNavigate();

  // const [instituteStudy, setInstituteStudy] = useState("");

  const handleStudyInstitute = (values:any) => {
    // localStorage.setItem("instituteStudy", values.instituteStudy);

    navigate("/location-gps");
  };

  // -------- Login form Initial Values -------
  const initialValues = {
    instituteStudy: "",
  };

  const loginvalidation = Yup.object().shape({
    instituteStudy: Yup.string()
    .required("Study Field is required")
    .min(5,"Study is too short")
  });

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
          <LogoWhiteDude />
          </Box>
         
          <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            <h2 className={styles.phone_texthead}>
              If Study is your thing....
            </h2>
            <Formik initialValues={initialValues} 
            // validationSchema={loginvalidation} 
            onSubmit={handleStudyInstitute} >
              <Form  style={{ width: "100%" }}>
                <Field
                  as={OutlinedInput}
                  placeholder="Enter Your Institute name"
                  name="instituteStudy"
                  // value={instituteStudy}
                  // onChange={(e) => setInstituteStudy(e.target.value)}
                  sx={{
                    width: "100%",
                    height: "60px",
                    "& .MuiOutlinedInput-input": { fontSize: "20px" },
                    "&.Mui-focused": {
                      border: "2px solid #E8E6EA",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    border: "1px solid #E8E6EA",
                    borderRadius: 1,
                    mt: 2,
                  }}
                />
                <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                  <ErrorMessage name="instituteStudy" />
                </p>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                    <button className="next_button" type="submit">
                      NEXT
                    </button>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                    <Box className="deny_button" onClick={() => navigate(-1)}>
                      BACK
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
            {/* <Box className="next_button" onClick={handleStudyInstitute} >
              NEXT
            </Box> */}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: { xs: 2, md: 3 }}}>
            <h4
              style={{ color: "#EC1B77", fontWeight: "500", cursor: "pointer" }}
              onClick={() => navigate("/location-gps")}
            >
              SKIP
            </h4>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default StudySignup;
