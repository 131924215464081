import {  Box, FormControl, Select, MenuItem } from "@mui/material";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { GetMethod } from "../api/fetch";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";

function LocationPreference() {
  const [yourState, setYourState] = useState("");
  const [city, setCity] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageCity, setErrorMessageCity] = useState("");
  const [fetchCities, setFetchCities] = useState([]);
  const [fetchStates, setFetchStates] = useState([]);
  const [cityId, setCityId] = useState(1);

  // console.log(yourState);

  const navigate = useNavigate();

  const handleChange = (event: any, item: any) => {
    setYourState(event.target.value);
  };

  const handleChangeSecond = (event: any) => {
    setCity(event.target.value);
  };

  const handleChangeObjectStates = (item: any) => {
    console.log(item);
    setCityId(item.id);
    localStorage.setItem("stateID", item.id);
  };

  const handleChangeObjectCity = (item: any) => {
    console.log(item);
    localStorage.setItem("cityID", item.id);
  };

  useEffect(() => {
    GetMethod("getConstants").then((res: any) => {
      console.log(res);
      setFetchStates(res?.data?.data?.STATES);
    });
  }, []);

  useEffect(() => {
    GetMethod(`getCities?state_id=${cityId}`).then((res: any) => {
      console.log(res.data.data);
      setFetchCities(res.data.data);
    });
  }, [cityId]);

  const handleSaveStateCity = () => {
    // setErrorMessageCity("");
    // setErrorMessage("");

    // if (city && yourState) {
    //   navigate("/looking-for");
    // }
    // if (!city) {
    //   setErrorMessageCity("Select Your City");
    // }
    // if (!yourState) {
    //   setErrorMessage("Select Your State");
    // }
    navigate("/looking-for");
    // }
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
        <Box className="login-section">
          <LogoWhiteDude />
          </Box>
         
          <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>

            <FormControl sx={{ width: "100%" }}>
              <Select
                 sx={{
                  width: "100%",
                  fontFamily: '"Josefin Sans", sans-serif', 
                  "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #cacaca",
                      }
                  },
                  mt: 2,
              }}
                value={yourState}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  Select Your State
                </MenuItem>
                {fetchStates?.map((item: any, index) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={item?.state_name}
                      onClick={(event) => handleChangeObjectStates(item)}
                      sx={{
                        fontFamily: '"Josefin Sans", sans-serif', 
                    }}
                    >
                      {item?.state_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <p style={{ color: 'red',fontSize:'14px',marginLeft:'13px' }}>{errorMessage}</p>
              <Select
             
                sx={{
                  width: "100%",
                  "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #cacaca",
                      }
                  },
                  mt: 2,
                  fontFamily: '"Josefin Sans", sans-serif', 
              }}
                value={city}
                onChange={handleChangeSecond}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  Select Your City
                </MenuItem>
                {fetchCities?.map((item: any) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={item?.city_name}
                      onClick={(event) => handleChangeObjectCity(item)}
                      sx={{
                        
                        fontFamily: '"Josefin Sans", sans-serif', 
                    }}
                    >
                      {item?.city_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <p style={{ color: 'red',fontSize:'14px',marginLeft:'13px' }}>{errorMessageCity}</p>
            </FormControl>

            <Grid container spacing={2}  sx={{ width: "100%" }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="next_button"
                  
                  onClick={handleSaveStateCity}
                >
                  NEXT
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="deny_button"
                  sx={{ width: "100%" }}
                  onClick={() => navigate(-1)}
                >
                  BACK
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LocationPreference;
