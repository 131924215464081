import React from 'react';
import { Grid, Box, FormControl, Select, MenuItem } from "@mui/material";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import arrow_icon from "../assets/arrow_icon.svg";


function PrivacyPolicy() {
  return (
    <>
    
      {/* <Box className="custom-margin" sx={{ display: "flex", justifyContent: "center"}}>
        <LogoWhiteDude />
      </Box> */}
      <Box className="custom-margin" >
        <Box className="main_top_heading">
          <span className="arrow_icon">
            <img src={arrow_icon} alt="arrow" />
          </span>
          <span className="main_sec_text">Privacy Policy</span>
        </Box>
        <Box className="main_sec_content">
          <p>
          1. Information We Collect
Personal Information:

When you sign up, we may collect personal details such as your name, email address, date of birth, gender, and sexual orientation. We also collect information you provide in your profile, including photos, hobbies, and interests.
Location Data:

With your permission, we may collect your location to show you nearby matches. This data can help improve your experience by providing more relevant matches based on proximity.
Usage Information:

We collect data on how you use our app, including interactions with other users, messaging behavior, and app preferences. This helps us improve app functionality and tailor the experience to your needs.


</p>
          <p>
          2. How We Use Your Information
We use your data to:

Provide personalized matches based on your profile and preferences.
Enable communication with other users through messages, likes, and other app features.
Improve and optimize the app through usage analytics.
Verify your identity to maintain a safe and authentic environment.
Send you important updates, notifications, and promotional content (with your consent).
</p>
          <p>
          3. Sharing Your Information
Third-Party Services:

We may share your information with third-party service providers who assist with app functionality, such as payment processors, customer support, or analytics. These parties are required to comply with strict data protection measures.
Legal Requirements:

We may disclose your information if required by law, to protect the safety of users, or in the event of a legal investigation.
</p>
          <p>
          4. Data Security
We take data security seriously and implement industry-standard safeguards to protect your personal information. However, please note that no method of transmission over the Internet is completely secure, and we cannot guarantee absolute security.</p>
        </Box>
        
      </Box>
    </>
  )
}

export default PrivacyPolicy;